import { useState, useEffect, useCallback } from 'react';
import { defaultConfig } from './default';

export type TConfig = {
    date: string;
    shortDate: string;
    dateTime: string;
    countDownImg: string;
    musicUrl: string;
    isSaleFooter: boolean;
    banner: {
        title: string;
        names: string;
        bgUrl: string;
    };
    info: {
        title1: string;
        text1: string;
        address: string;
        frame: string;
    };
    programOfDay: {
        title: string;
        list: Array<{
            time: string;
            title: string;
            text: string;
            img: string;
        }>;
    };
    dressCode: {
        title: string;
        text: string;
        colors: string[];
        img?: string;
    };
    hashtag: {
        title: string;
        text: string;
        hashtag: string;
    };
    wishes: {
        title: string;
        text: string;
    }
}

export const useGetConfig = () => {
    const [config, setConfig] = useState<TConfig | null>(null);

    const getConfig = useCallback(async () => {
        const { hostname } = window.location;

        if (hostname === 'localhost') {
            setConfig(defaultConfig);
            return;
        }

        fetch(`/config/${hostname}/config.json`).then((response) => response.json()).then((config) => {
            setConfig(config);
        });
    }, []);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    return config;
}