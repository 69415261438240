import React from 'react';

import styles from './styles.module.css';
import { TConfig } from '../../config/get-config';

export const Hashtag: React.FC<{ config: TConfig }> = ({ config }) =>  {
    const { hashtag  } = config;

    return (
        <div className="container">
            <div className="title">{hashtag.title}</div>
            <div className="text">{hashtag.text}</div>
            <div className={styles.hashtag}>{hashtag.hashtag}</div>
        </div>
    )
}