import React, { useEffect, useRef } from 'react';
import pluralize from 'plural-ru';

import styles from './styles.module.css';
import { TConfig } from '../../config/get-config';

export const CountDown: React.FC<{ config: TConfig }> = ({ config }) =>  {
    const ref = useRef(null);

    const { date, countDownImg, dateTime } = config;

    useEffect(() => {
        // @ts-ignore
        const block = ref?.current as HTMLElement;

        var countDownDate = new Date(dateTime).getTime();
        var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = countDownDate - now;

            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            block.innerHTML = `<div>${days} <span>${pluralize(days, 'день', 'дня', 'дней')}</span></div>` 
                + `<div>${hours} <span>${pluralize(hours, 'час', 'часа', 'часов')}</span></div>`
                + `<div>${minutes} <span>${pluralize(minutes, 'минута', 'минуты', 'минут')}</span></div>`
                + `<div>${seconds} <span>${pluralize(seconds, 'секунда', 'секунды', 'секунд')}</span></div>`;

            if (distance < 0) {
                clearInterval(x);
                block.innerHTML = "Уже состоялась ❤";
            }
        }, 1000);
    }, []);

    const img = countDownImg || require('../../img/IMG_1534.jpg')

    return (
        <div className={styles.bg} style={{backgroundImage: `url(${img})`}}>
            <div className={styles.content}>
                <div className={styles.title}>{date}</div>
                <div className={styles.text}>До свадьбы осталось</div>
                <div className={styles.date} ref={ref} />
            </div>
        </div>
    );
}