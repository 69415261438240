export const defaultConfig = {
    "date": "16 октября 2024",
    "shortDate": "16.10.2024",
    "dateTime": "2024-10-16T15:30:00",
    "musicUrl": "",
    "countDownImg": "",
    "isSaleFooter": true,
    "banner": {
        "title": "<sup>А</sup><sub>D</sub>",
        "names": "Александр и Дарья",
        "bgUrl": ""
    },
    "info": {
        "title1": "Дорогие родные и друзья",
        "text1": "Приглашаем вас разделить с нами радость особенного для нас события и стать частью нашей семейной истории",
        "address": "Банкетный зал \"Ленинские горки\" по адресу: г.Ульяновск, ул. Спуск Степана Разина, 33",
        "frame": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3291.672919180354!2d48.41127040949291!3d54.31165565214448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x415d37711679f94d%3A0xf762223bb19fff07!2z0JvQtdC90LjQvdGB0LrQuNC1INCT0L7RgNC60Lg!5e0!3m2!1sru!2sru!4v1699194800348!5m2!1sru!2sru"
    },
    "programOfDay": {
        "title": "Программа дня",
        "list": [
            {
                "time": "15:30",
                "title": "Сбор гостей",
                "text": "Собираясь на мероприятие, просим взять с собой ваши улыбки и хорошее настроение",
                "img": ""
            },
            {
                "time": "16:00",
                "title": "Церемония бракосочетания",
                "text": "На всякий случай приготовьте платочки для трогательного момента",
                "img": ""
            },
            {
                "time": "16:30",
                "title": "Начало банкета",
                "text": "Время вкусной еды, танцев и развлечений",
                "img": ""
            },
            {
                "time": "21:00",
                "title": "Свадебный торт",
                "text": "Сладкая традиция, которую мы не можем обойти стороной",
                "img": ""
            },
            {
                "time": "22:00",
                "title": "Окончание вечера",
                "text": "К сожалению, даже такой прекрасный вечер может закончится",
                "img": ""
            }
        ]
    },
    "dressCode": {
        "title": "Dress code",
        "text": "Нам будет очень приятно если вы поддержите цветовую гамму торжества и выберете наряды в соответствии с палитрой нашей свадьбы",
        "colors": ["#7E676F", "#DECBC5", "#C2BEB5", "#6A7D83", "#222A41"],
        "img": ""
    },
    "hashtag": {
        "title": "Свадебный хештег",
        "text": "Если вы будете выкладывать в социальных сетях фотографии, сделанные в день торжества, пожалуйста, используйте наш свадебный хештег",
        "hashtag": "#WeddingHashtag"
    },
    "wishes": {
        "title": "Пожелания",
        "text": "Будем признательны за альтернативу букетам в виде корма для собак, который мы потом передадим приюту для бездомных собак &#10084;"
    },
    "countDown": {
        "tutle": ""
    }
}