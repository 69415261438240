import React from 'react';

import { Banner } from './components/banner';
import { Header } from './components/header';
import { Section } from './components/ui/section';
import { Info } from './components/info';
import { DayProgram } from './components/day-program';
import { DressCode } from './components/dress-code';
import { Hashtag } from './components/hashtag';
import { Wishes } from './components/wishes';
import { CountDown } from './components/countdown';
import { Footer } from './components/footer';
import { useGetConfig } from './config/get-config';

function App() {
  const config = useGetConfig();
  if (!config) return <div>Ошибка</div>;

  const { isSaleFooter, wishes, hashtag, dressCode } = config;

  return (
    <>
      <Header config={config} />
      <Banner config={config} />
      <Section><Info config={config} /></Section>
      <Section><DayProgram config={config}/></Section>
      {dressCode && <Section><DressCode config={config}/></Section>}
      {hashtag && <Section><Hashtag config={config}/></Section>}
      {wishes && <Section><Wishes config={config} /></Section>}
      <Section><CountDown config={config}/></Section>
      {isSaleFooter && <Footer />}
    </>
  );
}

export default App;
