import React, { useRef, useState } from 'react';
import styles from './styles.module.css';

import m from '../../music/music2.mp3';
import { TConfig } from '../../config/get-config';

export const Header: React.FC<{ config: TConfig }> = ({ config }) => {
    const [isPlay, setIsPlay] = useState(false);
    const audio = useRef(null);
    const music = config?.musicUrl || m;

    const handlePlay = () => {
        if (isPlay) {
            // @ts-ignore
            audio?.current.pause();
            setIsPlay(false);
        } else {
            // @ts-ignore
            audio?.current.play();
            setIsPlay(true);
        }
    }

    return (
        <div className={styles.container}>
            Приглашение
            <span className={styles.music} onClick={handlePlay}>
                {isPlay ? <i className="fa-solid fa-pause"></i> : <i className="fa-solid fa-play"></i>}
                <span>
                    Музыка
                </span>
            </span>
            <audio ref={audio} className={styles.audio} controls src={music}>
                <a href={music}> Download audio </a>
            </audio>
        </div>
    )
}