import React from 'react';
import { TConfig } from '../../config/get-config';

export const Wishes: React.FC<{ config: TConfig }> = ({ config }) =>  {
    const { wishes } = config;

    return (
        <div className="container">
            <div className="title">{wishes.title}</div>
            <div className="text" dangerouslySetInnerHTML={{ __html: wishes.text }} />
        </div>
    );
}