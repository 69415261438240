import React from 'react';
import styles from './styles.module.css';
import { Section } from '../ui/section';
import { TConfig } from '../../config/get-config';

export const Info: React.FC<{ config: TConfig }> = ({ config }) =>  {
    const { info, shortDate } = config;

    return (
        <div className="container">
            <div className="title">{info.title1}</div>
            <div className="text">{info.text1}</div>
            <div className="title">{shortDate}</div>
            <div className="text">{info.address}</div>
            <Section>
                <div className="title">Как найти</div>
                <div className="text">Для вашего удобства мы подготовили карту. Надеемся, что вы легко найдете место проведения свадьбы и порадуете нас своим присутствием!</div>
                <iframe
                    className={styles.map}
                    src={info.frame}
                    title="map"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />
            </Section>
        </div>
    )
}