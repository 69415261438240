import React from 'react';
import styles from './styles.module.css';

type Props = {
    children: React.ReactNode;
}

export const Section: React.FC<Props> = ({children}) => (
    <div className={styles.container}>
        {children}
    </div>
)