import React from 'react';
import styles from './styles.module.css';
import { TConfig } from '../../config/get-config';

export const Banner: React.FC<{ config: TConfig }> = ({ config }) =>  {
    const { banner, date } = config;

    return (
        <div className={styles.container}>
            <img className={styles.img} src={banner.bgUrl || require('../../img/IMG_1818.jpg')} alt="" />
            <div className={styles.content}>
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: banner.title }} />
                <div className={styles.names}>{banner.names}</div>
                <div className={styles.separator} />
                <div className={styles.date}>{date}</div>
                <div className={styles.arrow}>
                    <i className="fa-solid fa-angles-down"></i>
                </div>
            </div>
        </div>
    )
}