import React from 'react';

import styles from './styles.module.css';
import { TConfig } from '../../config/get-config';

const getItem = (item: any, i: number) => {
    const url = item.img || require(`./img/${i + 1}.jpg`);
    
    return (
        <div className={`${styles.item} ${(i + 1) % 2 ? '' : styles.reverse}`} key={item.time}>
            {
                (i + 1) % 2 ? (
                    <div className={styles.column}>
                        <img className={styles.img} src={url} alt="" />
                    </div>
                ) : null
            }
            <div className={styles.column}>
                <div className={styles.content}>
                    <div className={styles.time}>{item.time}</div>
                    <div className={styles.title} dangerouslySetInnerHTML={{__html: item.title}} />
                    <div className={styles.text} dangerouslySetInnerHTML={{__html: item.text}} />
                </div>
            </div>
            {
                (i + 1) % 2 ? null : (
                    <div className={styles.column}>
                        <img className={styles.img} src={url} alt="" />
                    </div>
                )
            }
        </div>
    )
}

export const DayProgram: React.FC<{ config: TConfig }> = ({ config }) =>  {
    const { programOfDay } = config;

    return (
        <div className="container">
            <div className='title' style={{ marginBottom: '70px' }}>{programOfDay.title}</div>
            <div className={styles.list}>
                {
                    programOfDay.list.map((item, i) => getItem(item, i))
                }
            </div>
        </div>
    )
}