import React from 'react';

import styles from './styles.module.css';

export const Footer = () => (
    <div className={styles.container}>
        <div className={styles.text}>
            Для заказа такого сайта пиши <br />
            insta: <a target='_blank' href="https://www.instagram.com/chernovaa.diana" rel="noreferrer">chernovaa.diana</a> <br />
            email: <a href="mailto:di@chernovaa.ru">di@chernovaa.ru</a>
        </div>
    </div>
)