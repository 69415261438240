import React from 'react';

import styles from './styles.module.css';
import { TConfig } from '../../config/get-config';

export const DressCode: React.FC<{ config: TConfig }> = ({ config }) => {
    const { dressCode } = config;

    return (
        <div className="container">
            <div className="title">{dressCode.title}</div>
            <div className="text">{dressCode.text}</div>
            {dressCode.img && <img className={styles.img} src={dressCode.img} alt="" />}
            {
                dressCode.colors && (
                    <div className={styles.list}>
                        {dressCode.colors.map((item) =>
                            <div key={item} className={styles.item} style={{ backgroundColor: item }} />
                        )}
                    </div>
                )
            }
        </div>
    )
}